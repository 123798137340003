import React from "react";

interface IPhoneIcon {
  phoneStroke: string;
  width?: number;
  height?: number;
}

function PhoneIcon({ phoneStroke, width, height }: IPhoneIcon): JSX.Element {
  return (
    <svg
      className={`${phoneStroke}`}
      width={width ?? "100%"}
      height={height ?? "100%"}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 3.17423C1.49916 5.31786 1.92048 7.44065 2.73988 9.42135C3.55929 11.402 4.76074 13.2018 6.27558 14.7179C7.79043 16.234 9.589 17.4366 11.5686 18.2572C13.5481 19.0777 15.6698 19.5 17.8126 19.5C18.0018 19.4999 18.1856 19.4366 18.3347 19.32C18.4838 19.2035 18.5897 19.0404 18.6356 18.8567L19.4779 15.1966C19.5229 15.0031 19.4987 14.7999 19.4094 14.6225C19.3201 14.445 19.1714 14.3044 18.9893 14.2253L15.0477 12.54C14.8801 12.4677 14.6936 12.4518 14.5161 12.4947C14.3387 12.5376 14.18 12.637 14.064 12.778L12.3215 14.9136C9.58554 13.6208 7.38326 11.4176 6.09094 8.68048L8.21922 6.93726C8.36262 6.82159 8.46415 6.66208 8.50828 6.48316C8.55241 6.30424 8.53671 6.11578 8.46356 5.94665L6.77892 2.00994C6.69883 1.82849 6.5582 1.68051 6.38109 1.59134C6.20399 1.50216 6.00141 1.47732 5.80802 1.52107L2.14942 2.36373C1.96676 2.40785 1.8039 2.51136 1.68637 2.65804C1.56885 2.80472 1.50329 2.98627 1.5 3.17423Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PhoneIcon;
